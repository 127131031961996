import React from 'react';
import NavBar from './navigation/NavBar';
import Footer from './navigation/Footer';
import MainScreen from './screens/MainScreen';

function App() {
  return (
    <div className="flex flex-col w-full h-full">
      <NavBar />
      <MainScreen />
      <Footer />
    </div>
  );
}

export default App;
