import React from 'react';
import ImagePopup from  './ImagePopup';
import { ProgramDescription } from '../text/programDescription';

export default function MainScreen() {

  const newText = ProgramDescription.split('\n').map((str,ix) => <p key={ix} className='pb-2'>{str}</p>);
  return (
    <div className='relative flex flex-col flex-grow bg-slate-50'>
      <div className='flex justify-center text-center font-bold text-2xl w-full px-5 py-6'>
        <span>
            Do you have trouble remembering when (OR IF?) you took your medications?
            <br/>
            This can be particularly troublesome if you take your medications on an as-needed basis.
            <br/>
            If you don't remember when, or if, you could end up taking too many, or take them too often.
        </span>
      </div>
      <div className='w-full m-8'>
        <div className='flex flex-row items-center justify-center'>
          <div className='flex items-center justify-between'>
            <ImagePopup source='/assets/MedsScreen.png'/>
            <ImagePopup source='/assets/HistoryScreen.png'/>
            {/* <img className='h-[285px] w-[160px] mx-2 flex flex-col border-black border-2' alt='Med Screen' src='/assets/MedsScreen.png' /> */}
            {/* <img className='h-[285px] w-[160px] mx-2 flex flex-col border-black border-2' alt='History Screen' src='/assets/HistoryScreen.png'/> */}
          </div>
          <div className='text-lg ml-4 w-5/12'>
              We have found that most "medication apps" primarily remind you when to take your medications,
              but they are difficult to use when all you want to do is record when you actually took them.
          </div>
        </div>
      </div>
      <div className='h-full mb-12 flex flex-grow justify-center items-center scrl scroll-auto bg-gray-200'>
        <div className='mx-32 text-2xl'>{newText}</div>
      </div>
    </div>
  );
};

