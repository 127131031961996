import React, { useState } from 'react';
import './ImagePopup.css';

export default function ImagePopup({source}) {
    const [isOpen, setIsOpen] = useState(false);
    //console.log('ImagePopup', source);
    const handleShowDialog = () => {
      setIsOpen( !isOpen );
      //console.log('image cliked');
    };
  
    return (
    <div>
        <img 
            className='small mx-2' 
            alt='Med Screen' 
            src={source}
            onClick={()=>handleShowDialog()}
            />
        {isOpen && (
        <dialog
            className="dialog"
            style={{ position: 'absolute' }}
            onClick={()=>handleShowDialog()}
            open
        >
        <img 
            className='image' 
            alt='Med Screen' 
            src={source}
            onClick={handleShowDialog}
            />
        </dialog>
        )}
    </div>
    )
}

