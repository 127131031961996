import React from 'react'

export default function NavBar() {
  return (
    <div className='relative flex-row w-full bg-slate-700 h-24'>
        <div className='flex items-center h-full px-4'>
          <img className='w-14 h-14 ml-2 mr-8' src='/logo.png' alt='logo' />
          <span className='text-white font-bold text-3xl'>I Took It When?</span>
        </div>
    </div>
  );
}

